.header {
    justify-content: flex-end;
    flex-direction: row;
    text-align: flex-end;
}

.footer {
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
}

.title {
    color:#00b3c5;
    font-weight: bold;
    text-align: center;

}

.memberName {
    font-weight: 'bold';
    color: 'black';
}
.paper {
    padding: 2%;
    text-align: 'center';
    color: #00b3c5;
}

.banners {
    color:'white',

}

.text {
    color:'black';
    font-weight: 'bold';
}

.image-container {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center; /* Centra verticalmente */
}

.separador {
    color:white;
}

.position_banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 

}

.dropdown-menu {
  position: relative;
  display: inline-block;
  margin-bottom: 120px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: rgba(207, 207, 207, 0.5);
}

/* Estilos para los elementos de la lista */
.dropdown-menu ul li {
  padding: 10px 20px;
}

.dropdown-menu ul li:hover {
  background-color: #ddd;
}

.menu {
  margin-left: -12%;
}

.link {
  border: none; /* Elimina el borde */
  color: #424949;
  padding: 10px 20px; /* Ajusta el espaciado interno */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  text-align:left;
}

.link-recursos {
  border: none; /* Elimina el borde */
  color: #82b70c;
  padding: 10px 20px; /* Ajusta el espaciado interno */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  text-align:left;
  font-weight: bold;
}